import DashboardIcon from "./Dashboard";
import LinksManagementIcon from "./LinksManagement";
import QueueManagementIcon from "./QueueManagement";
import SessionsIcon from "./Sessions";

export default {
  SessionsIcon,
  DashboardIcon,
  LinksManagementIcon,
  QueueManagementIcon
};
