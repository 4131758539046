import Icons from "../../icons/sidebar";

const index = [
  // {
  //   title: "Dashboard",
  //   Icon: Icons.DashboardIcon,
  //   path: "/dashboard",
  // },
  {
    title: "Useful links",
    Icon: Icons.LinksManagementIcon,
    path: "/dashboard/links-grid",
  }
  ,
  {
    title: "Queue Management",
    Icon: Icons.QueueManagementIcon,
    path: "/dashboard/queue-management",
  }
];

export default index;
