import { Box, Button, Card } from "@mui/material";
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import toast from "react-hot-toast";
import FlexBox from "../../components/FlexBox";
import { H1 } from "../../components/Typography";


interface UserResponse {
  access_token: string;
}

interface UserProfile {
  picture: string;
  name: string;
  email: string;
}

const Login: FC = () => {
  const [user, setUser] = useState<UserResponse | null>(null);
  const [profile, setProfile] = useState<UserProfile | null>(null);

  // Load user from localStorage if available on component mount
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const loginWithGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
    },
    onError: (error) => {
      toast.success('Login Failed');
    },
  });

  useEffect(() => {
    if (user) {
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: 'application/json',
          },
        })
        .then((res) => {
              setProfile(res.data);
              localStorage.setItem('user', JSON.stringify(user));
              if (res.data) {
                localStorage.setItem('user picture', res.data.picture || '');
                localStorage.setItem('user email', res.data.email || '');
                localStorage.setItem('user name', res.data.name || '');
              }
              toast.success('You Logged In Successfully');
              window.location.reload();
        })
        .catch((err) => console.error(err));
    }
  }, [user]);

  return (
        <FlexBox
      sx={{
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: { sm: "100%" },
      }}
    >
      <Card sx={{ padding: 4, maxWidth: 600, boxShadow: 1 }}>
        <FlexBox
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          mb={5}
        >
          <Box width={38} mb={1}>
            <img src="https://res.cloudinary.com/tamarayul/image/upload/v1658759981/react-links-project/su0qe8ezrol92veq8d8g.svg" width="100%" alt="CS-App Logo" />
          </Box>
          <H1 fontSize={24} fontWeight={700}>
            Sign in to CS-App
          </H1>
        </FlexBox>

        <FlexBox alignItems="center"
          flexDirection="column"
          justifyContent="center">
            <Box sx={{ mt: 4 }}>
              <Button 
                  style={{backgroundColor: "#3448c5"}} variant="contained" size="small" onClick={() => loginWithGoogle()}>
                   Sign in with Google 🚀 
              </Button>
           </Box>
         </FlexBox>
      </Card>
     </FlexBox>
  );
};

const App: FC = () => {
  return (
    <GoogleOAuthProvider clientId="149267068000-ar8kt3nhhpm21hov9p71ldnlth4mknna.apps.googleusercontent.com">
        <Login />
    </GoogleOAuthProvider>
  );
};

export default App;
