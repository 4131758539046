import "nprogress/nprogress.css";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "react-18-image-lightbox/style.css";
import { BrowserRouter } from "react-router-dom";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import App from "./App";
import SettingsProvider from "./contexts/SettingsContext";
import TitleContextProvider from "./contexts/TitleContext";
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <StrictMode>
      <SettingsProvider>
        <TitleContextProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </TitleContextProvider>
      </SettingsProvider>
  </StrictMode>
);

//reportWebVitals();
