import { Badge, Box, ButtonBase, styled } from "@mui/material";
import SupportAvatar from "../../SupportAvatar";
import { FC, Fragment, useRef, useState } from "react";
import FlexBox from "../../FlexBox";
import { H6, Small, Tiny } from "../../Typography";
import PopoverLayout from "./PopoverLayout";

// styled components
const StyledSmall = styled(Small)(({ theme }) => ({
  display: "block",
  padding: "5px 1rem",
  cursor: "pointer",
  "&:hover": {
    color: theme.palette.primary.main,
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.secondary.light
        : theme.palette.divider,
  },
}));

const ProfilePopover: FC = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const userPicture = localStorage.getItem('user picture');
  const userName = localStorage.getItem('user name');

  return (
    <Fragment>
      <ButtonBase disableRipple ref={anchorRef} onClick={() => setOpen(true)}>
        <Badge
          overlap="circular"
          variant="dot"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          sx={{
            "& .MuiBadge-badge": {
              width: 11,
              height: 11,
              right: "7%",
              borderRadius: "50%",
              border: "2px solid #fff",
              backgroundColor: "success.main",
            },
          }}
        >
          <SupportAvatar
            src={userPicture || "/static/avatar/001-man.svg"}
            sx={{ width: 30, height: 30, ml: 1 }}
          />
        </Badge>
      </ButtonBase>

      <PopoverLayout
        hiddenViewButton
        maxWidth={230}
        minWidth={200}
        popoverOpen={open}
        anchorRef={anchorRef}
        popoverClose={() => setOpen(false)}
        title={
          <FlexBox alignItems="center">
            <SupportAvatar
              src={userPicture || "/static/avatar/001-man.svg"}
              sx={{ width: 35, height: 35 }}
            />

            <Box ml={1}>
              <H6>{userName}</H6>
              <Tiny display="block" fontWeight={500} color="text.disabled">
              </Tiny>
            </Box>
          </FlexBox>
        }
      >
      </PopoverLayout>
    </Fragment>
  );
};

export default ProfilePopover;
