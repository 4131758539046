import { FC, lazy, LazyExoticComponent, Suspense } from "react";
import { Navigate } from "react-router-dom";
import AuthGuard from "./components/authentication/AuthGuard";
import DashboardLayout from "./components/layouts/DashboardLayout";
import Kibana from "pages/queries/Kibana";

const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) =>
  (
    <Suspense fallback>
      <Component {...props} />
    </Suspense>
  );

// Dashboard pages
const DashboardSaaS = Loadable(lazy(() => import("./pages/dashboard/SaaS")));

const UsefulLinks = Loadable(
  lazy(() => import("./pages/linksManagement/LinksDB"))
);

const QueueManagement = Loadable(
  lazy(() => import("./pages/queueManagement/QueueManagement"))
);

// error
const Error = Loadable(lazy(() => import("./pages/404")));

// routes
const routes = [
  {
    path: "/",
    element: <Navigate to="dashboard/queue-management" />,
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout children={undefined} />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <DashboardSaaS />,
      },
      {
        path: "kibana-query",
        element: <Kibana/>,
      },
      {
        path: "links-grid",
        element: <UsefulLinks />,
      },
      {
        path: "queue-management",
        element: <QueueManagement />,
      },
    ],
  },
  {
    path: "*",
    element: <Error />,
  },
];

export default routes;
